import React, { useState, useEffect } from "react";
import CertificatesHr from "../components/lang/CertificatesHr";
import CompanyHr from "../components/lang/CompanyHr";
import FooterHr from "../components/lang/FooterHr";
import FsruHr from "../components/lang/FsruHr";
import HeaderHr from "../components/lang/HeaderHr";
const Home = () => {
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 300) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    });
  }, []);

  // This function will scroll the window to the top
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // for smoothly scrolling
    });
  };
  return (
    <>
      {showButton && (
        <button onClick={scrollToTop} className="toTopBtn">
          <i className="fas fa-arrow-up"></i>
        </button>
      )}
      <HeaderHr />
      <CompanyHr />
      <FsruHr />
      <CertificatesHr />
      <FooterHr />
    </>
  );
};

export default Home;
