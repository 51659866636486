import React, { useState, useEffect } from "react";
import FooterHr from "../components/lang/FooterHr";
import NavbarHr from "../components/lang/NavbarHr";
import ShipDraftHr from "../components/lang/ShipDraftHr";
import WhoHr from "../components/lang/WhoHr";
const About = () => {
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 300) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    });
  }, []);

  // This function will scroll the window to the top
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // for smoothly scrolling
    });
  };

  return (
    <>
      {showButton && (
        <button onClick={scrollToTop} className="toTopBtn">
          <i className="fas fa-arrow-up"></i>
        </button>
      )}
      <NavbarHr />
      <ShipDraftHr />
      <WhoHr />
      <FooterHr />
    </>
  );
};

export default About;
