import React from "react";

import c1 from "../../assets/img/c1.jpeg";
import c2 from "../../assets/img/c2.jpg";
import c3 from "../../assets/img/c3.jpg";
import c4 from "../../assets/img/c4.jpg";

const Certificates = () => {
  return (
    <>
      <section className="certificates container">
        <h1 className="main-title m-auto">Certifikati</h1>
        <div className="row align-items-center c_wrapper">
          <div className="col-md-3">
            <img src={c1} alt="Iso 14001:2015 certificate" />
          </div>
          <div className="col-md-3">
            <img src={c2} alt="Iso 45001:2018 certificate" />
          </div>
          <div className="col-md-3">
            <img src={c3} alt="Dedal certificate" />
          </div>
          <div className="col-md-3">
            <img src={c4} alt="Dedal certificate" />
          </div>
        </div>
      </section>
    </>
  );
};

export default Certificates;
