import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

import iso9001 from "../assets/pdf/9001en.pdf";
import iso14001 from "../assets/pdf/14001.pdf";
import iso29001 from "../assets/pdf/29001en.pdf";
import iso45001 from "../assets/pdf/45001.pdf";

import i9001 from "../assets/img/c3.jpg";
import i14001 from "../assets/img/c1.jpeg";
import i29001 from "../assets/img/c4.jpg";
import i45001 from "../assets/img/c2.jpg";

const Quality = () => {
  return (
    <>
      <Navbar />
      <section className="quality container py-5">
        <h1 className="main-title">Quality Management</h1>
        <div class="row">
          <div className="col-md-6">
            <div className="box-info">
              <img
                style={{ height: "80px", paddingBottom: "10px" }}
                src={i9001}
                alt="iso 9001 certificate"
              />
              <h3>ISO 9001</h3>
              <p>
                ISO 9001 is a set of standards for quality management systems.
                The application of this series has a significant benefit within
                the company (increase in efficiency, continuous improvement and
                increase in profit), as well as external (internationally
                recognized and recognizable quality management system, increase
                in opportunities in external markets and increase in
                customer/user satisfaction). The introduction of the ISO 9001
                quality management system significantly reduces the possibility
                of negative impact of business activities on the quality of
                products and services.
              </p>
              <a target={"_blank"} href={iso9001}>
                See certificate
              </a>
            </div>
          </div>
          <div className="col-md-6">
            <div className="box-info">
              <img
                style={{ height: "80px", paddingBottom: "10px" }}
                src={i14001}
                alt="iso 9001 certificate"
              />
              <h3>ISO 14001</h3>
              <p>
                ISO 14001 is a series of norms that establishes and monitors the
                implementation of norms related to environmental protection.
                Reasons for introducing the ISO 14001 series: the company's
                concern for the environment, compliance with legal regulations,
                pressure from interested parties, marketing opportunities,
                demands from banks and insurance companies, cost reduction and
                access to European business integrations. The certificate
                confirms that functional environmental management systems have
                been established, which contain precisely defined procedures,
                and that the negative impacts of all business activities, which
                may affect the environment, are reduced to the smallest possible
                extent.
              </p>
              <a href={iso14001} target="_blank">
                See certificate
              </a>
            </div>
          </div>
        </div>
        <div class="row">
          <div className="col-md-6">
            <div className="box-info">
              <img
                style={{ height: "80px", paddingBottom: "10px" }}
                src={i29001}
                alt="iso 9001 certificate"
              />
              <h3>ISO 29001</h3>
              <p>
                ISO 29001 is a direct result of a partnership between the ISO
                and the global oil and gas industry. It covers the oil and gas
                supply chain and is a continuation of the ISO 9001 quality
                management systems international standard. Certification to ISO
                29001 ensures standardization and continuous improvement within
                this sector. It includes additional requirements on preventing
                errors as well as reducing variation and waste (scrap) by
                service providers. It also provides overall consistency and
                increased assurance on the quality of supply of goods and
                services from suppliers. This is particularly important when
                failures in goods or services have serious consequences for the
                companies and industries involved.
              </p>
              <a href={iso29001} target="_blank">
                See certificate
              </a>
            </div>
          </div>
          <div className="col-md-6">
            <div className="box-info">
              <img
                style={{ height: "80px", paddingBottom: "10px" }}
                src={i45001}
                alt="iso 9001 certificate"
              />
              <h3>ISO 45001</h3>
              <p>
                ISO 45001 is an internationally recognized assessment of health
                and safety management system requirements. The norm was
                introduced to effectively manage risks for health and safety at
                work, reduce possible occurrences of injuries at work and
                occupational diseases, follow legal and other requirements,
                provide training and education of employees, readiness to
                respond in emergency cases, measure results and propose
                improvements. Bearing in mind the organization and management of
                business in accordance with global principles and following
                European directives, ASM Ltd. is certified in accordance with
                ISO 9001 standard (quality management system), ISO 14001
                standard (environmental management system), ISO 29001 standard
                (Petroleum, petrochemical and natural gas industries) and ISO
                45001 standard (health and safety management system).
              </p>
              <a href={iso45001} target={"_blank"}>
                See certificate
              </a>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Quality;
