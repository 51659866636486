import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import React, { Component, useRef } from "react";

import who1 from "../assets/img/who1.jpg";
import who2 from "../assets/img/who2.jpg";
import who3 from "../assets/img/who3.jpg";
import who4 from "../assets/img/who4.jpg";
import left from "../assets/img/left-arrow.svg";
import right from "../assets/img/right-arrow.svg";

const data = [
  {
    id: 1,
    img: who1,
    title: "Logistics",
    content: `The entire story of logistics process is delivering your customers what they want and when they
    want it while spending as little money as possible making it come true.
    ASM Ltd. is currently supporting the most exciting and rapidly changing sectors in the world, the oil
    &amp; gas, and renewables industries.`,
  },
  {
    id: 2,
    img: who2,
    title: "Shipping",
    content: `With a background in shipping and the full gambit of maritime consultancy services, ASM Ltd. has
    the experience and knowledge base to provide complete assistance in our full range of services as
    experienced ship chandler.
    ASM Ltd. is here to participate and aid your company’s projects and operations teams’
    understanding of all requirements and coordinate to ensure compliance with all milestones
    throughout the life of the project.`,
  },
  {
    id: 3,
    img: who3,
    title: "Policies and guiding principles",
    content: `Our policies and guiding principles
    ASM’s seven Guiding Principles are the core for all employees:

    Highest International Standards
    We maintain high standards and promote professional and ethical behavior.

    Believing the Key to Your Success is our Innovative Approach
    We provide strategically located office that works as extraordinary team to develop unique solutions
    for our customers.

    Exceptional Staff and Expertise
    We have fun and work hard successfully.`,
  },
  {
    id: 4,
    img: who4,
    title: "Better comunication and total commintment",
    content: `We understand that communication at a higher level than our industry benchmarks is the key to our
    success.
    We never promise what we can’t deliver.
    We act with integrity to deliver total commitment to customer service.
    We aim to be respected and recommended by building trust through the services we provide.
`,
  },
];

const Company = () => {
  const ref = useRef(null);

  const handleNextSlide = () => {
    ref.current.slickNext();
  };

  const handlePrevSlide = () => {
    ref.current.slickPrev();
  };

  const settings = {
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
  };

  return (
    <>
      <section className="company container">
        <h1 className="main-title">Our story</h1>
        {data.map((item) => (
          <div key={item.id} className="slider slider2">
            <div className="left-company">
              <img src={item.img} alt={item.title} />
            </div>
            <div className="service-content">
              <h2>{item.title}</h2>
              <p>{item.content}</p>
            </div>
          </div>
        ))}
      </section>
    </>
  );
};
export default Company;
