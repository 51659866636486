import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import React, { Component, useRef } from "react";

import who1 from "../../assets/img/who1.jpg";
import who2 from "../../assets/img/who2.jpg";
import who3 from "../../assets/img/who3.jpg";
import who4 from "../../assets/img/who4.jpg";

const data = [
  {
    id: 1,
    img: who1,
    title: "Logistika",
    content: `Cijela bit procesa logistike je isporučiti Vašim klijentima ono što žele i kada to žele, a da pritom potrošite što manje novca do realizacije. Društvo ASM d.o.o. trenutno podržava najuzbudljivije sektore u svijetu koji se brzo mijenjaju, industriju nafte i plina te obnovljive izvore energije. Zahvaljujući našim prilagođenim logističkim uslugama i mreži pravovremenog prijevoza, Vaše će poslovanje imati konkurentnu prednost. Obratite se našem lokalnom predstavniku društva ASM d.o.o. kako biste saznali više o tome kako možemo podržati Vaše logističko planiranje i kretanje dobara.`,
  },
  {
    id: 2,
    img: who2,
    title: "Transport dobara na brodove",
    content: `S iskustvom brodarstvu i cijelim spektrom pomorskih konzultantskih usluga, društvo ASM d.o.o. posjeduje iskustvo i bazu znanja za pružanje potpune asistencije u našem punom rasponu usluga kao iskusni opskrbljivač brodova. Društvo ASM d.o.o. prisustvom na tržištu sudjeluje i pomaze projektima Vaše društva te operativnim timovima omogućuje bolje razumijevanje svih zahtjeva koordinirajući kako bi se osigurala usklađenost sa svim značajnim točkama razvoja tijekom cijelog životnog vijeka projekta. Ne dostavljamo samo Vaša dobra; vec rješavamo složene I vremenski zahtjevne izazove dostave pošiljaka.`,
  },
  {
    id: 3,
    img: who3,
    title: "Naše Poslovne Politike i Vodeći Principi",
    content: `Poslovna politika zaštite zdravlja i sigurnosti na radu. Društvo je predano procesu upravljanja rizicima i osigurava, koliko je to razumno izvedivo, da su svi zaposlenici, izvođači, partneri adekvatno informirani i da će raditi u okruženju bez opasnosti koje mogu uzrokovati osobne ozljede. Društvo ASM d.o.o. vjeruje da će ovaj ključni cilj biti od koristi svim ljudima koji su uključeni u aktivnosti i da će se postići samo stalnim promicanjem i poboljšanjem sigurnih radnih praksi, kontrolom opasnosti, sviješću o sigurnosti i predanošću sigurnosti od strane svake uključene osobe.
    Svaki menadžer društva ASM d.o.o. je odgovoran za performanse zaštite na radu u svom segmentu, funkciji, projektu i poslovnom procesu. Svaki je zaposlenik odgovoran za poštivanje uputa i postupaka zaštite na radu te za prijavu svake sigurnosne nesukladnosti na koju naiđe u svom radu.
    `,
  },
  {
    id: 4,
    img: who4,
    title: "Bolja komunikacija i potpuna predanost",
    content: `Shvaćamo da je komunikacija na višoj razini od standarda naše industrije ključ našeg uspjeha.
    Nikada ne obećavamo ono što ne možemo ispuniti. Djelujemo s integritetom kako bismo pružili potpunu predanost korisnicima.
    Cilj nam je da nas poštuju i preporučuju izgradnjom povjerenja kroz usluge koje pružamo.
    
    `,
  },
];

const Company = () => {
  const ref = useRef(null);

  const handleNextSlide = () => {
    ref.current.slickNext();
  };

  const handlePrevSlide = () => {
    ref.current.slickPrev();
  };

  const settings = {
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
  };

  return (
    <>
      <section className="company container">
        <h1 className="main-title">Naša priča</h1>
        {data.map((item) => (
          <div key={item.id} className="slider slider2">
            <div className="left-company">
              <img src={item.img} alt={item.title} />
            </div>
            <div className="service-content">
              <h2>{item.title}</h2>
              <p>{item.content}</p>
            </div>
          </div>
        ))}
      </section>
    </>
  );
};
export default Company;
