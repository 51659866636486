import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import React, { useRef } from "react";

import service from "../assets/img/service.jpg";
import work from "../assets/img/work.jpg";
import who from "../assets/img/who.jpg";
import years from "../assets/img/years.jpg";
import left from "../assets/img/left-arrow.svg";
import right from "../assets/img/right-arrow.svg";

const data = [
  {
    id: 1,
    img: work,
    title: "Work",
    content: `
    ASM Ltd. is a newly incorporated company specialising in the operation and
    maintenance of LNG carriers (LNGC), Floating Storage and Regasification Units
    (FSRU), and Floating Storage Units (FSU). With a commitment to safety,
    efficiency, and environmental responsibility, we provide comprehensive
    services that ensure the reliable and cost-effective operation of these critical
    components in the LNG supply chain.
     Our mission is to deliver world-class operation and maintenance services for
    LNG carriers, FSRUs, and FSUs, promoting safe, efficient, and environmentally
    responsible LNG operations worldwide. We aim to support our clients in meeting
    the global demand for clean energy through our commitment to excellence. 
     We serve a wide range of clients, including energy companies, LNG producers,
    traders, import terminals, and governmental organizations, ensuring the
    efficient and reliable operation of their LNG assets.
    `,
  },
  {
    id: 2,
    img: service,
    title: "Service",
    content: `The Company is offering as well wide range of activities as procurement, operations, and ship
    chandler services.
    As current consultants and associates of LNG CROATIA terminal (FSRU), Island Krk, Croatia – we are
    present in the liquefaction, transportation, and regasification of natural gas scene.
    ASM Ltd.’s flexible and low-cost LNG based management solutions are designed to thrive in a low
    commodity price environment.`,
  },
  {
    id: 3,
    img: who,
    title: "Who are we?",
    content: `We are local based company in our Office in Rijeka with international interest with selected team of
    independent experts with experience in the respective professional areas: Managing Director and
    Board Member of international major company specialising in gas industry, as owner and operator
    of marine LNG infrastructure, vessel Master’s and Cargo Engineers, Accounting and Administration
    specialists, Procurement experts.
    `,
  },
  {
    id: 4,
    img: years,
    title: "20 years of experience",
    content: `20 years of professional and business expertise within the maritime industry with best practices in
    gas sector, having held senior managing positions at LNG corporations in Europe and overseas.
    `,
  },
];

const Company = () => {
  const ref = useRef(null);

  const handleNextSlide = () => {
    ref.current.slickNext();
  };

  const handlePrevSlide = () => {
    ref.current.slickPrev();
  };

  const settings = {
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
  };

  return (
    <>
      <section className="company container" id="more">
        <h1 className="main-title">Our company</h1>
        <Slider ref={ref} settings={settings}>
          {data.map((item) => (
            <div key={item.id} className="slider">
              <div className="left-company">
                <img src={item.img} alt={item.title} />
              </div>
              <div className="service-content">
                <h2>{item.title}</h2>
                <p>{item.content}</p>
              </div>
            </div>
          ))}
        </Slider>

        <div className="buttons">
          <button type="button" onClick={handlePrevSlide}>
            <img src={left} alt="left-arrow" />
          </button>
          <button type="button" onClick={handleNextSlide}>
            <img src={right} alt="right-arrow" />
          </button>
        </div>
      </section>
    </>
  );
};
export default Company;
