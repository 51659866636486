import React from "react";

import lngh from "../../assets/img/lngh.webp";
import jps from "../../assets/img/jps.png";
import jps2 from "../../assets/img/jps2.png";
import coolco from "../../assets/img/coolco-logo.png";
import scan from "../../assets/img/scan-logo.png";
import golar from "../../assets/img/golar-logo.png";
const Footer = () => {
  return (
    <>
      <div className="container">
        <h4 className="main-title">Partneri</h4>
        <div className="row align-items-center py-5">
          <div className="col-md-4 text-center">
            <img src={lngh} alt="LNG Hrvatska" />
          </div>
          <div className="col-md-4 text-center">
            <img src={jps} alt="SCAFI" />
          </div>
          <div className="col-md-4 text-center">
            <img src={jps2} alt="Jadranski pomorski servis" />
          </div>
        </div>
        <div className="row align-items-center py-5">
          <div className="col-md-4 text-center">
            <img src={golar} alt="Golar" />
          </div>
          <div className="col-md-4 text-center">
            <img src={scan} alt="Scan" />
          </div>
          <div className="col-md-4 text-center">
            <img style={{ width: "100%" }} src={coolco} alt="CoolCo" />
          </div>
        </div>
      </div>
      <footer className="footer">
        <div className="footer__wrapper">
          <div className="footer_wrap">
            <div className="footer-logo">
              <h1 className="logo">ASM</h1>
              <h4>Adria Shipping Management</h4>
            </div>
            <div className="footer-links">
              <ul>
                <li>
                  <a href="/hr">Naslovna</a>
                </li>
                <li>
                  <a href="/abouthr">O nama</a>
                </li>
                <li>
                  <a href="/quality-managementhr">Sustav upravljanja</a>
                </li>
                <li>
                  <a href="/contacthr">Kontaktirajte nas</a>
                </li>
              </ul>
            </div>
            <div className="info">
              <a
                href="https://goo.gl/maps/VuR7PJc6bRkb2a2f6"
                className="location"
              >
                Lokacija
              </a>
              <a href="mailto:info@asm.hr" className="telephone">
                Email
              </a>
            </div>
          </div>
          <div className="copyright__wrapper">
            <p className="copyright">© 2021 ASM Ltd. All rights reserved.</p>
            <p>
              Dizajn i implementacija{" "}
              <a
                className="vik"
                href="https://www.instagram.com/viktorbilokapic/"
              >
                VB
              </a>
            </p>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
