import React, { useState, useEffect } from "react";
import Certificates from "../components/Certificates";
import Company from "../components/Company";
import Footer from "../components/Footer";
import Fsru from "../components/Fsru";
import Header from "../components/Header";
const Home = () => {
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 300) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    });
  }, []);

  // This function will scroll the window to the top
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // for smoothly scrolling
    });
  };
  return (
    <>
      {showButton && (
        <button onClick={scrollToTop} className="toTopBtn">
          <i className="fas fa-arrow-up"></i>
        </button>
      )}
      <Header />
      <Company />
      <Fsru />
      <Certificates />
      <Footer />
    </>
  );
};

export default Home;
