import React from "react";
import Navbar from "../components/lang/NavbarHr";
import Contact1 from "../components/lang/ContactHr";
import Footer from "../components/lang/FooterHr";

const Contact = () => {
  return (
    <>
      <Navbar />
      <Contact1 />
      <Footer />
    </>
  );
};

export default Contact;
