import React from "react";

import video from "../assets/img/video.mp4";
const ShipDraft = () => {
  return (
    <>
      <div className="container">
        <div className="ship__wrapper">
          <div className="responsive-content">
            <h1 className="main-title">About us</h1>
            <h2 className="sub-title">Socially Responsible...</h2>
            <p>
              We adopt socially responsible practices to ensure we have a
              positive impact in countries in which we operate.
            </p>
          </div>
        </div>
        <video style={{ borderRadius: "10px" }} width={"100%"} autoPlay muted>
          <source src={video} type="video/mp4" />
        </video>
      </div>
    </>
  );
};

export default ShipDraft;
