import React from "react";
import ilu from "../../assets/img/ilu.svg";

const Fsru = () => {
  return (
    <>
      <section className="fsru container" style={{ paddingBottom: "100px" }}>
        <h1 className="main-title">FSRU Iskustvo u Konverzijama</h1>
        <div className="row align-items-center" style={{ paddingTop: "50px" }}>
          <div className="col-md-6">
            <img
              style={{ width: "100%", paddingBottom: "20px" }}
              src={ilu}
              alt="fsru"
            />
          </div>
          <div className="col-md-6">
            <p>
              Naša snažna interna tehnička stručnost i naša sposobnost da radimo
              s ograničenim rizikom zbog programa izgradnje projekata, omogućuje
              nam da iskoristimo jake osnove u segmentu ukapljivanja, transporta
              i regasifikacije prirodnog plina (FSRU). Prepoznajemo zahtjevne
              zadatke s kojima se FSRU projekt može suočiti te smo spremni
              pružiti tehničku podršku i savjetovanje kako bismo osigurali
              uspješnu realizaciju.
            </p>
            <p>
              Temeljita procjena plovila prije preinake ključna je za uspjeh
              projekta koji je izravno povezan s glavnim odgovornostima kao što
              su održavanje i rad jedince, tehnička dokumentacija kojom se
              utvrđuju tehnički standardi, nabava rezervnih dijelova, praćenje i
              optimizacija uz razmjenu relevantnih iskustava vezanih za projekt.
            </p>
            <p>
              Naši su stručnjaci sa svojim iskustvima u raznim projektima
              ukapljivanja, transporta i regasifikacije prirodnog plina (FSRU) u
              brodogradilištu <b>Keppel u Singapuru</b> uspjesno preuzimali
              inženjerske i tehničke odgovornosti. Društvo ASM d.o.o.
              potpomognuto praktičnim iskustvom i svojim iznimnim poznavanjem
              tehničke ekspertize može pružiti smjernice prije te tijekom i
              nakon konverzije.
            </p>

            {/* <p>
              Konzultantske usluge drustva ASM d.o.o. pokrivaju širok raspon
              kompetencija i područja usluga u sektoru ukapljivanja, transporta
              i regasifikacije prirodnog plina te pružajući vrijednu podršku pri
              donošenju odluka pomažući u izbjegavanju pogrešaka u upravljanju
              time poboljšavajući sigurnost, energetsku učinkovitost i
              korporativne vrijednosti.
            </p> */}
          </div>
        </div>
      </section>
    </>
  );
};

export default Fsru;
