import React from "react";
import Navbar from "../components/lang/NavbarHr";
import Footer from "../components/lang/FooterHr";

import iso9001 from "../assets/pdf/9001hr.pdf";
import iso14001 from "../assets/pdf/14001.pdf";
import iso29001 from "../assets/pdf/29001en.pdf";
import iso45001 from "../assets/pdf/45001.pdf";

import i9001 from "../assets/img/c3.jpg";
import i14001 from "../assets/img/c1.jpeg";
import i29001 from "../assets/img/c4.jpg";
import i45001 from "../assets/img/c2.jpg";

const Quality = () => {
  return (
    <>
      <Navbar />
      <section className="quality container py-5">
        <h1 className="main-title">Sustav upravljanja</h1>
        <div class="row">
          <div className="col-md-6">
            <div className="box-info">
              <img
                style={{ height: "80px", paddingBottom: "10px" }}
                src={i9001}
                alt="iso 9001 certificate"
              />
              <h3>ISO 9001</h3>
              <p>
                ISO 9001 je skup standarda za sustave upravljanja kvalitetom.
                Primjena ovih standarda ima značajnu korist unutar tvrtke
                (povećanje učinkovitosti, stalno poboljšanje i povećanje
                dobiti), kao i eksterno (međunarodno priznat i prepoznatljiv
                sustav upravljanja kvalitetom, povećanje mogućnosti na vanjskim
                tržištima i povećanje zadovoljstvo kupca/korisnika). Uvođenjem
                sustava upravljanja kvalitetom ISO 9001 značajno se smanjuje
                mogućnost negativnog utjecaja poslovnih aktivnosti na kvalitetu
                proizvoda i usluga.
              </p>
              <a target={"_blank"} href={iso9001}>
                Pogledaj certifikat
              </a>
            </div>
          </div>
          <div className="col-md-6">
            <div className="box-info">
              <img
                style={{ height: "80px", paddingBottom: "10px" }}
                src={i14001}
                alt="iso 9001 certificate"
              />
              <h3>ISO 14001</h3>
              <p>
                ISO 14001 je niz normi koji uspostavlja i prati provedbu normi
                vezanih uz zaštitu okoliša. Razlozi za uvođenje serije ISO
                14001: briga tvrtke za okoliš, poštivanje zakonskih propisa,
                pritisak zainteresiranih strana, marketinške mogućnosti,
                zahtjevi banaka i osiguravajućih društava, smanjenje troškova i
                pristup europskim poslovnim integracijama. Certifikat potvrđuje
                da su uspostavljeni funkcionalni sustavi upravljanja okolišem
                koji sadrže precizno definirane procedure te da su negativni
                utjecaji svih poslovnih aktivnosti koji mogu utjecati na okoliš
                svedeni na najmanju moguću mjeru.
              </p>
              <a href={iso14001} target="_blank">
                Pogledaj certifikat
              </a>
            </div>
          </div>
        </div>
        <div class="row">
          <div className="col-md-6">
            <div className="box-info">
              <img
                style={{ height: "80px", paddingBottom: "10px" }}
                src={i29001}
                alt="iso 9001 certificate"
              />
              <h3>ISO 29001</h3>
              <p>
                ISO 29001 izravan je rezultat partnerstva između ISO-a i
                globalne industrije nafte i plina. Pokriva lanac opskrbe naftom
                i plinom i nastavak je međunarodnog standarda sustava
                upravljanja kvalitetom ISO 9001. Certifikacija prema ISO 29001
                osigurava standardizaciju i kontinuirano poboljšanje unutar ovog
                sektora. Uključuje dodatne zahtjeve za sprječavanje pogrešaka
                kao i smanjenje varijacija i otpada (otpada) od strane
                pružatelja usluga. Također pruža ukupnu dosljednost i povećano
                osiguranje kvalitete opskrbe robom i uslugama od dobavljača. Ovo
                je osobito važno kada kvarovi u robi ili uslugama imaju ozbiljne
                posljedice za uključene tvrtke i industrije.
              </p>
              <a href={iso29001} target="_blank">
                Pogledaj certifikat
              </a>
            </div>
          </div>
          <div className="col-md-6">
            <div className="box-info">
              <img
                style={{ height: "80px", paddingBottom: "10px" }}
                src={i45001}
                alt="iso 9001 certificate"
              />
              <h3>ISO 45001</h3>
              <p>
                ISO 45001 je međunarodno priznata procjena zahtjeva sustava
                upravljanja zdravljem i sigurnošću. Norma je uvedena radi
                učinkovitog upravljanja rizicima za zdravlje i sigurnost na
                radu, smanjenja mogućih pojava ozljeda na radu i profesionalnih
                bolesti, praćenja zakonskih i drugih zahtjeva, osiguranja
                osposobljavanja i obrazovanja zaposlenika, spremnosti za
                reagiranje u hitnim slučajevima, mjerenja rezultata i
                predlaganja poboljšanja.
              </p>
              <a href={iso45001} target={"_blank"}>
                Pogledaj certifikat
              </a>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Quality;
