import React, { useRef } from "react";
import emailjs from "emailjs-com";
const Contact1 = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_y8vca87",
        "template_dyualg2",
        form.current,
        "9vkjTWjVijkceqsmZ"
      )
      .then(
        (result) => {
          console.log(result.text);
          alert("Poruka je uspješno poslana!");
        },
        (error) => {
          console.log(error.text);
          alert("Poruka nije poslana!");
        }
      );
  };

  return (
    <section className="contacted container py-5">
      <h1 className="main-title">Kontaktirajte nas</h1>
      <div className="row align-items-center py-5">
        <div className="col-md-6">
          <form ref={form} onSubmit={sendEmail}>
            <label for="name">Ime i prezime*</label>
            <input type="text" name="name" placeholder="Ime i prezime" />
            <label for="email">Email*</label>
            <input type="email" name="email" placeholder="Email" />
            <label for="message">Poruka*</label>
            <textarea
              name="message"
              type="text"
              placeholder="Poruka"
              id="textarea"
              cols="20"
              rows="10"
            ></textarea>
            <button
              class="submit-btn contact-button"
              type="submit"
              name="submit"
            >
              Pošalji poruku
            </button>
          </form>
        </div>
        <div className="col-md-6">
          <div className="main-office">
            <h4>Glavni ured</h4>
            <p>PON - PET 9:00 AM - 5:00PM</p>
            <p>ADRIA SHIPPING MANAGEMENT D.O.O.</p>
            <a href="tel:+385 998128721">+385 998128721</a>
          </div>
          <div className="map">
            <iframe
              title="map"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2893.525883873301!2d16.433958475914764!3d43.512225271109166!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x13355dfc43f6ef67%3A0xa631191c26e59f8f!2sUl.%20Petra%20Ili%C4%8Da%20%C4%8Cajkovskog%2010%2C%2021000%2C%20Split!5e0!3m2!1shr!2shr!4v1694533113571!5m2!1shr!2shr"
              width="600"
              height="450"
              style={{ border: "0" }}
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact1;
