import React, { useState } from "react";

import bigAsm from "../assets/img/ASM.svg";

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toogleMenu = () => {
    setIsOpen(!isOpen);
  };

  // If i click on link, the menu should be closed
  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <header>
        <div className="header--wrapper container">
          <nav className="nav">
            <div className="logo">
              <h1>ASM</h1>
            </div>
            <div className="links">
              <ul>
                <li>
                  <a href="/">Main</a>
                </li>
                <li>
                  <a href="/about">About</a>
                </li>
                <li>
                  <a href="/quality-management">Quality Management</a>
                </li>
                <li className="lang">
                  <a className={"chosen-lang"} href="/">
                    EN
                  </a>
                  <a style={{ color: "#fff" }} href="/hr">
                    HR
                  </a>
                </li>
                <li>
                  <a href="/contact" className="contact">
                    Contact us
                  </a>
                </li>
              </ul>
            </div>
            <div className="overlay" style={{ width: isOpen ? "100%" : "0%" }}>
              <ul>
                <li>
                  <a href="/">Main</a>
                </li>
                <li>
                  <a href="/about">About</a>
                </li>
                <li className="lang">
                  <a
                    className={"chosen-lang"}
                    style={{ color: "white" }}
                    href="/"
                  >
                    EN
                  </a>
                  <a href="/hr">HR</a>
                </li>
                <li>
                  <a onClick={handleClick} href="/quality-management">
                    Quality Management
                  </a>
                </li>
                <li>
                  <a
                    style={{ color: "#fff" }}
                    onClick={handleClick}
                    className="contact"
                    href="/contact"
                  >
                    Contact
                  </a>
                </li>
              </ul>
            </div>
            <div className="hamburger" onClick={toogleMenu}>
              <svg
                className={
                  isOpen ? "active ham hamRotate ham8" : "ham hamRotate ham8"
                }
                viewBox="0 0 100 100"
                width="80"
              >
                <path
                  className="line top"
                  d="m 30,33 h 40 c 3.722839,0 7.5,3.126468 7.5,8.578427 0,5.451959 -2.727029,8.421573 -7.5,8.421573 h -20"
                />
                <path className="line middle" d="m 30,50 h 40" />
                <path
                  className="line bottom"
                  d="m 70,67 h -40 c 0,0 -7.5,-0.802118 -7.5,-8.365747 0,-7.563629 7.5,-8.634253 7.5,-8.634253 h 20"
                />
              </svg>
            </div>
          </nav>
          <div className="left-section">
            <h1>Gas transport...</h1>
            <p>
              Adria Shipping Management Ltd. (ASM Ltd.) is maritime Consultancy
              prescient specialised in Shipping and Logistics industry, focusing
              on consultancy and management.
            </p>
            <a href="#more" className="contact">
              Read More
            </a>
          </div>
        </div>
        <div className="asm-wrapper">
          <img src={bigAsm} alt="asm" />
        </div>
      </header>
    </>
  );
};

export default Header;
