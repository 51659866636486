import React from "react";
import ilu from "../assets/img/ilu.svg";

const Fsru = () => {
  return (
    <>
      <section className="fsru container" style={{ paddingBottom: "100px" }}>
        <h1 className="main-title">FSRU Conversion Experience</h1>
        <div className="row align-items-center" style={{ paddingTop: "50px" }}>
          <div className="col-md-6">
            <img
              style={{ width: "100%", paddingBottom: "20px" }}
              src={ilu}
              alt="fsru"
            />
          </div>
          <div className="col-md-6">
            <p>
              FSRU Conversion Experience – Services based on our extensive
              experience. Our strong in-house technical expertise and our
              ability to perform with limited risk due to building programme,
              enables us to take advantage of the strong fundamentals in the
              FSRU segment. We recognize the daunting tasks that an FSRU project
              may face, and we are ready to provide both technical support and
              consultancy to ensure a thriving maritime venture.
            </p>
            <p>
              Thorough assessment of the vessel before conversion is essential
              for the success of the project, linked to main responsibilities
              and accountabilities such as maintenance and operation, technical
              documentation establishing technical standards, spare parts
              procurement, monitoring and optimisation with experience sharing.
            </p>
            <p>
              Our experts have assisted in various FSRU projects at the
              <b> Keppel shipyard in Singapore </b> undertaking engineering and
              technical authority. Backed by practical experience and its
              profound knowledge of technical expertise, ASM Ltd. can provide
              guidance before, during and after conversion. ASM Ltd. consultancy
              services cover a wide range of LNG and FSRU competence and service
              areas, providing valuable decision support, helping avoid
              management mistakes, and improving safety, energy efficiency and
              ultimately corporate value.
            </p>
          </div>
        </div>
      </section>
    </>
  );
};

export default Fsru;
