import { Suspense } from "react";

//Import router
import { Route, Routes, useLocation } from "react-router-dom";

// Import components and pages
import Home from "./pages/Home";
import About from "./pages/About";
import Quality from "./pages/Quality";
import Contact from "./pages/Contact";

// Imports of english pages
import HomeHr from "./pages/HomeHr";
import AboutHr from "./pages/AboutHr";
import QualityHr from "./pages/QualityHr";
import ContactHr from "./pages/ContactHr";

import "./scss/Main.scss";

function App() {
  const location = useLocation();

  return (
    <>
      <Routes location={location} key={location.pathname}>
        <Route path="/" element={<Home />} />
        <Route path="/hr" element={<HomeHr />} />
        <Route path="/about" element={<About />} />
        <Route path="/abouthr" element={<AboutHr />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/contacthr" element={<ContactHr />} />
        <Route path="/quality-management" element={<Quality />} />
        <Route path="/quality-managementhr" element={<QualityHr />} />
      </Routes>
    </>
  );
}

export default App;
