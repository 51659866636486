import React from "react";

import video from "../../assets/img/video.mp4";
import shipCargo from "../../assets/img/shipcargo.svg";
const ShipDraft = () => {
  return (
    <>
      <div className="container">
        <div className="ship__wrapper">
          <img
            style={{ width: "100%" }}
            className="ship-cargo"
            src={shipCargo}
            alt="Ship Cargo"
          />
          <div className="responsive-content">
            <h1 className="main-title">O nama</h1>
            <h2 className="sub-title">Društveno odgovorni...</h2>
            <p>
              Usvajamo društveno odgovorne prakse kako bismo osigurali pozitivan
              učinak u zemljama u kojima poslujemo.
            </p>
          </div>
          <video style={{ borderRadius: "10px" }} width={"100%"} autoPlay muted>
            <source src={video} type="video/mp4" />
          </video>
        </div>
      </div>
    </>
  );
};

export default ShipDraft;
