import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import React, { useRef } from "react";

import service from "../../assets/img/service.jpg";
import work from "../../assets/img/work.jpg";
import who from "../../assets/img/who.jpg";
import years from "../../assets/img/years.jpg";
import left from "../../assets/img/left-arrow.svg";
import right from "../../assets/img/right-arrow.svg";

const data = [
  {
    id: 1,
    img: work,
    title: "Naši ciljevi",
    content: `   
    ASM d.o.o. je novoosnovana tvrtka specijalizirana za poslovanje i održavanje nosača UPP-a (LNGC), plutajućih skladišnih i uplinjavajućih jedinica (FSRU) i plutajuće skladišne jedinice (FSU). Uz predanost sigurnosti, učinkovitost i odgovornost za okoliš, pružamo sveobuhvatnu usluge koje osiguravaju pouzdan i isplativ rad tih ključnih komponente u lancu opskrbe UPP-om. Naša misija je pružiti vrhunske usluge rada i održavanja za Prijevoznici UPP-a, FSRU-ovi i FSU-ovi, koji promiču sigurno, učinkovito i ekološko odgovorne operacije UPP-a širom svijeta. Cilj nam je podržati naše klijente u susretu globalna potražnja za čistom energijom kroz našu predanost izvrsnosti.  Opslužujemo širok raspon klijenata, uključujući energetske tvrtke, proizvođače UPP-a, trgovci, terminali za uvoz i vladine organizacije, osiguravajući učinkovit i pouzdan rad njihove imovine za UPP.
    `,
  },
  {
    id: 2,
    img: service,
    title: "20 godina iskustva",
    content: `20 godina stručnog i poslovnog znanja u pomorskoj industriji s najboljim praksama u plinskom sektoru, nakon što je bio na višim rukovodećim pozicijama u LNG korporacijama u Europi i inozemstvu. Stručnjaci asm d.o.o. imaju visoko ocijenjene rezultate u pomorskom sektoru, posebno u odnosu na prestižni FSRU sektor.
    Temeljitim znanjem i iskustvom te dokazanim i dokazanim iskustvom u LNG industriji možemo pružiti svoju poslovnu viziju i našu priznatu stručnost u tom pogledu.
    `,
  },
  {
    id: 3,
    img: who,
    title: "ASM d.o.o. posluje na globalnoj razini",
    content: `Redovito putujemo iz Riječkog ureda našim klijentima kako bismo kao konzultanti omogućili usluge s uspjehom osiguravajući da su sve potrebe ispunjene.
    Aktivni smo i u podupiranju projekata i operacija u suradnji s drugim ugovornim stranama na međunarodnoj razini u Europi.
    Naš prilagođeni pristup kombinira duboki uvid u pomorsku dinamiku naše tvrtke kupaca uz blisku suradnju na svim razinama.
    
    `,
  },
  {
    id: 4,
    img: years,
    title: "Tko smo?",
    content: `Adria Shipping Management d.o.o. je specijalizirano društvo  u industriji prijevoza i logistike, sa fokusom na savjetovanju i upravljanjem, usmjereno postizanje uspjeha za naše kupce nudeći specifična rješenja za naftnu i plinsku industriju.
    `,
  },
];

const Company = () => {
  const ref = useRef(null);

  const handleNextSlide = () => {
    ref.current.slickNext();
  };

  const handlePrevSlide = () => {
    ref.current.slickPrev();
  };

  const settings = {
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
  };

  return (
    <>
      <section className="company container" id="more">
        <h1 className="main-title">Naše društvo</h1>
        <Slider ref={ref} settings={settings}>
          {data.map((item) => (
            <div key={item.id} className="slider">
              <div className="left-company">
                <img src={item.img} alt={item.title} />
              </div>
              <div className="service-content">
                <h2>{item.title}</h2>
                <p>{item.content}</p>
              </div>
            </div>
          ))}
        </Slider>

        <div className="buttons">
          <button type="button" onClick={handlePrevSlide}>
            <img src={left} alt="left-arrow" />
          </button>
          <button type="button" onClick={handleNextSlide}>
            <img src={right} alt="right-arrow" />
          </button>
        </div>
      </section>
    </>
  );
};
export default Company;
