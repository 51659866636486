import React, { useState } from "react";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toogleMenu = () => {
    setIsOpen(!isOpen);
  };

  // If i click on link, the menu should be closed
  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="container">
      <nav className="nav">
        <div className="logo">
          <h1>ASM</h1>
        </div>
        <div className="links">
          <ul>
            <li>
              <a style={{ color: "#181818" }} href="/">
                Main
              </a>
            </li>
            <li>
              <a style={{ color: "#181818" }} href="/about">
                About
              </a>
            </li>
            <li>
              <a style={{ color: "#181818" }} href="/quality-management">
                Quality Management
              </a>
            </li>
            <li className="lang">
              <a href="/" className={"chosen-lang"}>
                EN
              </a>
              <a href="/hr" style={{ color: "#181818" }}>
                HR
              </a>
            </li>
            <li>
              <a href="/contact" style={{ color: "#fff" }} className="contact">
                Contact us
              </a>
            </li>
          </ul>
        </div>
        <div className="overlay" style={{ width: isOpen ? "100%" : "0%" }}>
          <ul>
            <li>
              <a href="/">Main</a>
            </li>
            <li>
              <a href="/about">About</a>
            </li>
            <li>
              <a onClick={handleClick} href="/quality-management">
                Quality Management
              </a>
            </li>
            <li className="lang">
              <a style={{ color: "#fff" }} className={"chosen-lang"} href="/">
                EN
              </a>
              <a href="/hr">HR</a>
            </li>
            <li>
              <a
                onClick={handleClick}
                style={{ color: "white" }}
                className="contact"
                href="/contact"
              >
                Contact
              </a>
            </li>
          </ul>
        </div>
        <div className="hamburger" onClick={toogleMenu}>
          <svg
            className={
              isOpen ? "active ham hamRotate ham8" : "ham hamRotate ham8"
            }
            viewBox="0 0 100 100"
            width="80"
          >
            <path
              className="line top"
              d="m 30,33 h 40 c 3.722839,0 7.5,3.126468 7.5,8.578427 0,5.451959 -2.727029,8.421573 -7.5,8.421573 h -20"
            />
            <path className="line middle" d="m 30,50 h 40" />
            <path
              className="line bottom"
              d="m 70,67 h -40 c 0,0 -7.5,-0.802118 -7.5,-8.365747 0,-7.563629 7.5,-8.634253 7.5,-8.634253 h 20"
            />
          </svg>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
